
<template>
  <!-- 会员权益体系 -->
  <div>
    <div class="heading">
      <h3>产品更新</h3>
      <span>___</span>
      <p>PRODUCT</p>
    </div>
    <div class="renew">
      <h5>产品更新</h5>
      <p class="title">
        在数字化进程的市场驱动下，随着酒店业态的多样化，所需的管理系统也需要不断更新精进，数字化软件的更新也必须紧跟其后。
      </p>
      <div class="renew_c">
        <div class="content_l">
          <img :class="renew==1?'img2':'img1'" :src="renew==1?img1:img2" />
          <img :class="renew==2?'img2':'img1'" :src="renew==2?img1:img2" />
          <img :class="renew==3?'img2':'img1'" :src="renew==3?img1:img2" />
          <img :class="renew==4?'img2':'img1'" :src="renew==4?img1:img2" />
        </div>
        <div class="content_r">
          <a @mousemove="onrenew(1)" href="https://mp.weixin.qq.com/s/92rjyMUvG9wGlN0iyd7Wng">
            <p :class="renew==1?'number':'number_w'">1</p>
            <p :class="renew==1?'renew_t':'renew_w'">
              微心动系统功能更新全新3.2.0版正式上线啦，一起跟着小编去看看都有哪些变化吧~
            </p>
          </a>
          <a @mousemove="onrenew(2)" href="https://mp.weixin.qq.com/s/gbqzQ2OTlf6npsRCGVVpbQ">
            <p :class="renew==2?'number':'number_w'">2</p>
            <p :class="renew==2?'renew_t':'renew_w'">
              微心动系统后台数据统计报表全新3.1.0版正式上线，酒店服务我们在进步！
            </p>
          </a>
          <a @mousemove="onrenew(3)" href="https://mp.weixin.qq.com/s/Kdc9DyupPsZQgdgClIZltQ">
            <p :class="renew==3?'number':'number_w'">3</p>
            <p :class="renew==3?'renew_t':'renew_w'">
              微心动系统商城页面全新3.0.0版正式上线，酒店服务我们在进步！
            </p>
          </a>
          <a @mousemove="onrenew(4)" href="https://mp.weixin.qq.com/s/iwVMkg-fy2LHmd8fKqokdw">
            <p :class="renew==4?'number':'number_w'">4</p>
            <p :class="renew==4?'renew_t':'renew_w'">
              微心动系统后台页面全新2.1.6版正式上线，酒店服务我们在进步！
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
        img1:require("../../assets/images/product_z.png"),
        img2:require("../../assets/images/product_w.png"),
        renew:1,
    };
  },
  created() {},
  mounted() {},
  methods: {
      onrenew(i){
          this.renew=i
      }
  },
};
</script>

<style scoped lang="less">
.heading {
  height: 6.6rem;
  background-image: url(../../assets/images/consult01.png);
  background-size: 100% 100%;
  text-align: center;
  color: #fff;
  h3 {
    font-size: 0.56rem;
    padding-top: 2.05rem;
  }
  span {
    font-weight: 600;
    font-size: 0.4rem;
  }
  p {
    width: 7.43rem;
    margin: 1em auto 0;
    font-size: 0.36rem;
    line-height: 0.3rem;
  }
}
.renew {
  padding: 1.06rem 0 1.3rem;
  h5 {
    text-align: center;
    font-size: 0.28rem;
    padding-bottom: 0.48rem;
  }
  .title {
    text-align: center;
    font-size: 0.2rem;
    color: #333;
    padding-bottom: 0.42rem;
  }
  .renew_c {
    height: 3.6rem;
    margin: 0 4.18rem;
    background-image: url(../../assets/images/product_bj.png);
    background-size: 100% 100%;
    display: flex;
    .content_l {
      padding-top: 0.2rem;
      .img1 {
        display: block;
        width: 0.24rem;
        height: 0.24rem;
        margin-left: 0.55rem;
        margin-bottom: 0.65rem;
      }
      .img2 {
        display: block;
        width: 0.36rem;
        height: 0.36rem;
        margin-left: 0.5rem;
        margin-bottom: 0.66rem;
      }
    }
    .content_r {
      padding-top: 0.05em;
      a {
        display: flex;
        padding-bottom: 0.18rem;
        .number {
          width: 1.07rem;
          height: 0.52rem;
          font-size: 0.28rem;
          line-height: 0.52rem;
          color: #fff;
          border-radius: 0.08rem;
          text-align: center;
          background-color: #ffc53d;
          margin-left: 0.64rem;
          margin-top: 0.1rem;
        }
        .number_w {
          width: 1.07rem;
          height: 0.52rem;
          font-size: 0.28rem;
          line-height: 0.52rem;
          color: #ffc53d;
          border-radius: 0.08rem;
          text-align: center;
          background-color: #ffedc3;
          margin-left: 0.64rem;
          margin-top: 0.1rem;
        }
        .renew_t {
          font-size: 0.18rem;
          width: 7.86rem;
          height: 0.71rem;
          line-height: 00.71rem;
          background-color: #323232;
          color: #fff;
          border-radius: 0.08rem;
          margin-left: 0.95rem;
          box-sizing: border-box;
          padding-left: 0.48rem;
        }
        .renew_w {
          font-size: 0.18rem;
          width: 7.86rem;
          height: 0.71rem;
          line-height: 00.71rem;
          background-color: #f2f2f2;
          border-radius: 0.08rem;
          margin-left: 0.95rem;
          box-sizing: border-box;
          padding-left: 0.48rem;
        }
      }
    }
  }
}
</style>
